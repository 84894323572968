<template>
  <div id="web">
    <div class="homeDownload-list" v-if="title!='产品下载'" @mouseleave="downloadLeave">
      <div class="homeDownload-list-right">
        <div class="homeDownload-list-right-item" :style="{backgroundColor:downloadtitle==item.title?'#E0F0FF':'#fff'}" v-for="(item,index) in downloadList" :key="index" @mouseenter="downloadClick(item.title)">
          <img :src="item.img" alt="">
          <p class="title">{{ item.title }}</p>
          <div class="homeDownload-list-right-phone" @mouseleave="downloadLeave" v-if="downloadtitle=='电话咨询'&&item.title=='电话咨询'">
            <p>请拨打以下热线</p>
            <p>{{ detailList.phone }}</p>
          </div>
          <div class="homeDownload-list-right-code" @mouseleave="downloadLeave" v-if="downloadtitle=='微信咨询'&&item.title=='微信咨询'">
            <img :src="detailList.wxUrl" alt="" srcset="">
            <p>扫一扫联系微信客服</p>
          </div>
          <div class="homeDownload-list-right-message" @mouseleave="downloadLeave" v-if="downloadtitle=='留言咨询'&&item.title=='留言咨询'">
            <div class="homeDownload-list-right-message-top">
              <p>留言咨询</p>
            </div>
            <div class="homeDownload-list-right-message-center">
              <p>请留下您的信息，我们将尽快与您联系</p>
              <input type="text" placeholder="请填写您的姓名" v-model="queryParams.name">
              <input type="text" placeholder="请填写您的联系电话（必填）" v-model="queryParams.phone">
              <textarea name="" id="" placeholder="请描述一下您的需求" v-model="queryParams.demandDescribe"></textarea>
            </div>
            <div class="homeDownload-list-right-message-bottom">
              <button @click="messageLeave">提交</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navBox">
      <div class="nav">
        <div class="nav-left" @click="goHome">
          <img src="../../assets/top-logo.png" alt="">
        </div>
        <div class="nav-right">
          <router-link :to="{name:item.name}" v-for="item in navList" :key="item.title">
            <div class="navMenuItem" @click="navChange(item.title)" @mouseenter="navHoverTitle(item.title)">
              <img src="../../assets/Web/home/icon.png" alt="" srcset="" v-if="title==item.title">
              <p :style="{  color:title==item.title?'#000000':'#C6C6C6',
          fontWeight:title==item.title?'bold':'500'}">
                {{ item.title}}
              </p>
            </div>
          </router-link>
          <div class="casePop" v-if="hoverTitle=='开发案例'" >
            <div class="casePop-list" @mouseleave="navHoverTitle(' ')">
              <p v-for="(item ,index) in caseList" :key="index" @click="casePopClick(item.name)">{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-view class="paddTop" :casePopName="casePopName" @casePopNameChange="casePopNameChange" :navTitle="title" @navTitleChange="navChange" @downloadClick="downloadClick"></router-view>
    <div class="webFooter">
      <div class="webFooter-left">
        <div class="webFooter-left-left">
          <img src="../../assets/logo.png" alt="" srcset="">
          <!-- <div class="webFooter-left-left-img"></div> -->
          <!-- <p>千述科技</p> -->
        </div>
        <div class="webFooter-left-right">
          <router-link :to="{name:item.name}" v-for="item in navList" :key="item.title">
            <div @click="navChange(item.title)">
              {{ item.title}}
            </div>
          </router-link>
        </div>
      </div>
      <div class="webFooter-right">
        <div class="webFooter-right-list">
          <div></div>
          <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备2022010263号</a>
        </div>
        <div class="webFooter-right-list">
          <div></div>
          <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">闽公网安备35021102001747</a>
        </div>
        <div class="webFooter-right-list">
          <div></div>
          <a @click="open">增值电信业务经营许可证：闽B2-20221070</a>
        </div>
        <div class="webFooter-right-list">
          <div></div>
          <a>版权所有©千述信息科技（厦门）有限公司</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { messageLeave, contactInfo } from '@/api/index.js'
import imageUrl from "@/assets/imageUrl.png"
export default {
  data() {
    return {
      detailList: {},
      queryParams: {
        demandDescribe: '',
        name: '',
        phone: '',
      },
      casePopName: '',
      hoverTitle: '',
      title: '首页',
      navList: [
        {
          title: '首页',
          name: 'Home'
        },
        {
          title: '开发案例',
          name: 'Case'
        },
        {
          title: '求职定制',
          name: 'Job'
        },
        {
          title: '关于我们',
          name: 'About'
        },
        {
          title: '联系我们',
          name: 'Contact'
        },
      ],
      caseList: [
        {
          title: '会员营销系统',
          name: 'member'
        },
        {
          title: '数学化报修系统',
          name: 'digitization'
        },
        {
          title: '商城小程序',
          name: 'shopping'
        },
        {
          title: '家政上门服务小程序',
          name: 'housekeeping'
        },
        {
          title: '无人值守预约小程序',
          name: 'unmanned'
        },
        {
          title: '蛋糕预约小程序',
          name: 'cakeSubscribe'
        },
        {
          title: '线上云酒馆',
          name: 'cloudTavern'
        },
        {
          title: 'CRM系统',
          name: 'crm'
        },
      ],
      downloadtitle: '',
      downloadList: [
        // { img: require('../../assets/Web/home/1.png'), title: '电话咨询' },
        { img: require('../../assets/Web/home/2.png'), title: '微信咨询' },
        { img: require('../../assets/Web/home/3.png'), title: '留言咨询' },
      ],
    }

  },
  mounted() {
    this.contactInfo()
  },
  methods: {
    open() {
      const image = new Image()
      image.src = imageUrl
      image.onload = () => {
        image.style.margin = "0 auto"
        image.style.display = "block"
        const newWin = window.open("", "_blank")
        newWin.document.write(image.outerHTML)
      }
    },
    contactInfo() {
      contactInfo().then(res => {
        this.detailList = res.data
      })
    },
    messageLeave() {
      messageLeave({
        demandDescribe: this.queryParams.demandDescribe,
        name: this.queryParams.name,
        phone: this.queryParams.phone,
      }).then(res => {
        if (res.code == 200) {
          this.queryParams.demandDescribe = ''
          this.queryParams.name = ''
          this.queryParams.phone = ''
          this.$message({
            message: '提交成功',
            type: 'success'
          })
        } else {
          this.$message.error('提交失败');
        }
      })
    },
    goHome() {
      this.$router.push({ name: 'Home' })
    },
    casePopClick(name) {
      this.casePopName = name
      if (this.title != '开发案例') {
        this.title = '开发案例'
        this.$router.push({ name: 'Case' })
      }
      this.hoverTitle = ''
    },
    navHoverTitle(title) {
      this.hoverTitle = title
      this.casePopName = ''
    },
    casePopNameChange(name){
      this.casePopName=name
    },
    navChange(title) {
       if(this.title=='开发案例'){
        this.casePopName='top'
      }
      this.title = title
      window.scrollTo(0, 0);
    },
    downloadClick(downloadtitle) {
      if (this.downloadtitle != downloadtitle) {
        this.downloadtitle = downloadtitle
      } else {
        // this.downloadtitle = ''
      }
    },
    downloadLeave() {
      this.downloadtitle = ''
    }
  }
}
</script>
<style lang="scss">
#web {
  min-width: 1366PX;
}
input,
textarea {
  outline: none;
}
.paddTop {
  padding-top: 79PX;
}
* {
  margin: 0;
  padding: 0;
  font-size: 14PX;
}
a {
  text-decoration: none;
}
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14PX;
}
#web{
  .homeSolve {
  margin-top: 87PX;
  margin-bottom: 85PX;
  // padding-left: 410PX;
  // padding-right: 410PX;
  display: flex;
  justify-content: center;
  .homeSolve-content {
    width: 782PX;
    // width: 1100PX;
    height: 428PX;
    border-radius: 10PX;
    box-shadow: 0PX 0PX 15PX 3PX rgba(144, 144, 144, 0.16);
    // box-shadow: 0PX 0PX 10PX 6PX rgba($color: #909090, $alpha: 0.16);
    .homeSolve-content-top {
      padding-top: 38PX;
      display: flex;
      flex-direction: column;
      align-items: center;
      p:first-child {
        color: #000000;
        font-size: 26PX;
        font-weight: bold;
        margin-bottom: 10PX;
      }
      p:last-child {
        color: #000000;
        font-size: 13PX;
      }
    }
    .homeSolve-content-center {
      padding-left: 32PX;
      padding-right: 32PX;
      margin-top: 35PX;
      .homeSolve-content-center-top {
        display: flex;
        margin-bottom: 22PX;
        input:first-child {
          margin-right: 36PX;
        }
        input {
          flex: 1;
          height: 38PX;
          border: 2PX solid #eeeeee;
          border-radius: 8PX;
          padding-left: 13PX;
          padding-right: 13PX;
          font-size: 12PX;
        }
      }
      textarea {
        border-radius: 10PX;
        width: 100%;
        height: 156PX;
        border: 2PX solid #eeeeee;
        font-size: 12PX;
        padding: 15PX;
        box-sizing: border-box;
        resize: none;
      }
    }
    .homeSolve-content-bottom {
      margin-top: 17PX;
      display: flex;
      justify-content: center;
      button {
        width: 114PX;
        height: 35.6PX;
        background-color: #006bf5;
        border-radius: 10PX;
        border: none;
        color: #ffffff;
        font-size: 14.24PX;
      }
    }
  }
}
}
.homeDownload-list {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  margin-right: 60PX;
  position: fixed;
  right: 0PX;
  z-index: 1000;

  .homeDownload-list-right {
    background-color: #fff;
    width: 72PX;
    // min-width: 72PX;
    // height: 472PX;
    height: 226PX;
    // min-height: 336PX;
    border-radius: 35PX;
    margin-left: 30PX;
    margin-top: 156PX;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // box-shadow: 0PX 7PX 26PX 6PX rgba(0, 107, 245, 0.18);
    // box-shadow: 0PX 0PX 18PX 5PX rgba($color: #006BF5, $alpha: 0.18);
    box-shadow: 0PX 0PX 30PX 6PX rgba(0, 107, 245, 0.18);

    .homeDownload-list-right-item:first-child {
      margin-top: 14PX;
    }
    .homeDownload-list-right-item:last-child {
      // margin-bottom: 14PX;
    }
    .homeDownload-list-right-item {
      // padding-top: 26PX;
      // padding-bottom: 26PX;
      padding-left: 5PX;
      padding-right: 5PX;
      flex: 1;
      border-radius: 41PX;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: 14PX;
      img {
        width: 38PX;
        height: 38PX;
        // min-width: 38PX;
        // min-height: 38PX;
      }
      .title {
        font-family: SourceHanSansCN-Medium;
        font-size: 12PX;
        color: #006bf5;
        margin-top: 4PX;
        // font-weight: bold;
      }
    }
    .homeDownload-list-right-code,
    .homeDownload-list-right-phone,
    .homeDownload-list-right-message {
      position: absolute;
      right: 90PX;
      display: flex;
      flex-direction: column;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-shadow: 0PX 0PX 12PX 1PX rgba(139, 139, 139, 0.4);
      // box-shadow: 0PX 0PX 9PX 5PX rgba($color: #8B8B8B, $alpha: 0.4);
    }
    .homeDownload-list-right-phone:before {
      content: "";
      width: 0;
      height: 0;
      border-top: 20PX solid transparent;
      border-left: 20PX solid #fff;
      border-bottom: 15PX solid transparent;
      position: absolute;
      right: -10PX;
      top: 20%;
    }
    .homeDownload-list-right-code:before {
      content: "";
      width: 0;
      height: 0;
      border-top: 20PX solid transparent;
      border-left: 20PX solid #fff;
      border-bottom: 15PX solid transparent;
      position: absolute;
      right: -10PX;
      top: 10%;
    }
    .homeDownload-list-right-phone {
      width: 225PX;
      // height: 91PX;
      border-radius: 10PX;
      padding-top: 10PX;
      padding-bottom: 10PX;
      padding-left: 25PX;
      background-color: #fff;
      // background-image: url("../../assets/Web/home/bg1.png");
      justify-content: center;
      p:first-child {
        font-size: 16PX;
        color: #333333;
        font-weight: bold;
      }
      p:last-child {
        font-size: 18PX;
        color: #006bf5;
        font-weight: bold;
      }
    }
    .homeDownload-list-right-code {
      align-items: center;
      // width: 198PX;
      border-radius: 10PX;
      // height: 289PX;
      padding: 10PX 16PX 10PX 16PX;
      justify-content: center;
      background-color: #fff;
      // background-image: url("../../assets/Web/home/bg2.png");
      top: 20PX;
      p {
        font-size: 14PX;
        color: #333333;
        font-family: SourceHanSansCN-Medium;
        margin-top: 4PX;
      }
      img {
        width: 130PX;
        height: 130PX;
      }
    }
    .homeDownload-list-right-message {
      align-items: center;
      border-radius: 10PX;
      width: 270PX;
      height: 338PX;
      background-color: #fff;
      .homeDownload-list-right-message-top {
        border-radius: 10PX 10PX 0PX 0PX;
        width: 100%;
        height: 40PX;
        background-color: #006bf5;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          font-size: 18PX;
          color: #ffffff;
          font-family: SourceHanSansCN-Medium;
        }
      }
      .homeDownload-list-right-message-center {
        padding-left: 15PX;
        padding-right: 15PX;
        p {
          font-size: 14PX;
          color: #666666;
          // font-weight: bold;
          margin-top: 9PX;
          margin-bottom: 10PX;
        }
        input,
        textarea {
          width: 100%;
          margin-bottom: 9PX;
          color: #000;
          font-size: 14PX;
          border-radius: 6PX;
          padding-left: 11PX;
          padding-right: 11PX;
          box-sizing: border-box;
          border: 1PX solid #eeeeee;
        }
        input {
          height: 34PX;
        }
        textarea {
          height: 120PX;
          padding-top: 11PX;
          resize: none;
        }
      }
      .homeDownload-list-right-message-bottom {
        width: 100%;
        padding-left: 12PX;
        padding-right: 12PX;
        box-sizing: border-box;
        margin-bottom: 9PX;
        button {
          width: 100%;
          background-color: #006bf5;
          height: 26PX;
          color: #fff;
          font-size: 14PX;
          border-radius: 6PX;
          border: none;
        }
      }
    }
  }
}
.navBox {
  min-width: 1920PX;
  width: 100%;
  height: 79PX;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 1200;
  position: fixed;
  border-bottom: 0.5PX solid #f6f6f6;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // justify-content: flex-end;
  align-items: center;
  min-width: 1366PX;
  width: 100%;
  height: 79PX;
  position: fixed;
  top: 0;
  .nav-left {
    margin-left: 140PX;
    img {
      width: 197PX;
      height: 49PX;
    }
  }
  .nav-right {
    margin-right: 100PX;
    display: flex;
    position: relative;
    .navMenuItem:hover .nav-right .casePop {
    }
    .casePop {
      position: absolute;
      background-color: #ffffff;
      border-radius: 10PX;
      width: 230PX;
      height: 412PX;
      top: 61PX;
      left: 43PX;
      box-shadow: 0PX 0PX 10PX 2PX rgba($color: #b7b7b7, $alpha: 0.3);
      opacity: 1;
      cursor: pointer;
      .casePop-list {
        height: 412PX;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // align-items: center;
        padding-top: 30PX;
        padding-bottom: 30PX;
        padding-left: 33PX;
        padding-right: 33PX;
        box-sizing: border-box;
        text-align: center;
        p {
          letter-spacing: 0.2PX;
          font-size: 18PX;
          font-family: SourceHanSansCN-Medium;
          color: #333;
        }
      }
    }
    .navMenuItem {
      // padding-left: 26PX;
      // padding-right: 26PX;
      height: 46PX;
      margin-right: 40PX;
      display: flex;
      align-items: center;
      position: relative;
      img {
        position: absolute;
        width: 12PX;
        height: 8PX;
        margin-right: 3PX;
        left: -12PX;
        top: 13PX;
      }
      p {
        white-space: pre;
        font-size: 24PX;
      }
    }
  }
}
.webFooter {
  height: 241.2PX;
  background-color: #272b2f;
  padding: 56PX 0PX 56PX 142PX;
  display: flex;
  align-items: center;
  .webFooter-left {
    border-right: 2PX solid rgba($color: #ffffff, $alpha: 0.6);
    display: flex;

    .webFooter-left-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 115PX;
      align-items: center;
      // img{
      //   width: 88PX;
      //   height: 130PX;
      // }
      .webFooter-left-left-img {
        border-radius: 50%;
        background-color: #c6c6c6;
        width: 88PX;
        height: 130PX;
      }
      p {
        font-size: 20PX;
        color: #fff;
        margin-top: 21PX;
        font-family: SourceHanSansCN-Medium;
      }
    }
    .webFooter-left-right {
      margin-right: 54PX;
      a {
        color: #fff;
        font-family: SourceHanSansCN-Medium;
        font-size: 16PX;
      }
      a:hover {
        color: #c6c6c6;
      }
      a:last-child div {
        margin-bottom: 0PX;
      }
      div {
        margin-bottom: 19PX;
      }
    }
  }
  .webFooter-right {
    flex: 1;
    padding-left: 56PX;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .webFooter-right-list:last-child {
      margin-bottom: 0PX;
    }
    .webFooter-right-list {
      display: flex;
      align-items: center;
      margin-bottom: 22PX;
      cursor: pointer;
      div {
        width: 5PX;
        height: 5PX;
        background-color: #fff;
        margin-right: 15PX;
        border-radius: 50%;
      }
      a {
        color: #fff;
        font-size: 16PX;
        text-decoration: none;
        font-family: SourceHanSansCN-Medium;
      }
      a:hover {
        color: #c6c6c6;
      }
    }
  }
}
</style>
    